import React from "react";

const Solutions = () => {
  return (
    <>
      <main className="relative lg:mt-28 w-full flex-1 mx-auto overflow-x-hidden max-w-[1400px] false">
        <div className="flex flex-col items-center justify-start h-full px-4 overflow-x-hidden pt-6">
          <div className="mb-10 sm:my-16 heightSmall:my-3 h-full w-full px-4 overflow-x-hidden pt-10">
            <div className="flex flex-col lg:flex-row items-center justify-center gap-10 lg:gap-32">
              <div className="flex-col max-w-[400px]">
                <div className="flex justify-center lg:justify-start">
                  <img
                    alt="reward icon"
                    src="https://powered.by.dustlabs.com/cdn-cgi/image/width=48/https://e99de883.os-4cs.pages.dev/y00ts//y00t-point.svg"
                    width="22"
                    height="24.86"
                  />
                </div>
                <div className="max-w-[400px] flex flex-col justify-center items-center lg:items-start !w-full !text-left">
                  <div>
                    <h2 className="text-center text-lightFontPrimary py-2 !text-[20px] md:!text-xl leading-[18px] lg:leading-4">
                      Building the Bridge to Web3.
                    </h2>
                  </div>
                  <div>
                    <h2 className="text-center text-lightFontPrimary py-2 !text-[26px] md:!text-5xl font-primaryBold whitespace-nowrap">
                      Solutions
                    </h2>
                  </div>
                  <div>
                    <p className="md:text-lg !text-base !text-fontSecondary text-justify px-4 lg:px-0 !leading-4">
                      y00ts and the Polygon foundation have joined forces to develop simple,
                      sustainable and scalable solutions that enhance the transition of{" "}
                      <span className="font-primaryBold">brands to Web3</span> by leveraging and
                      utilizing IP. More details coming in Q3 2023.
                    </p>
                    <p className="md:text-lg mt-6 text-left !text-base !text-fontSecondary lg:text-left lg:pr-0 pl-2 !leading-4">
                      “We&apos;re here to put a dent in the universe. Otherwise why else even be
                      here?” - Steve Jobs
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-[340px] false border-2 border-lightBorder italic flex flex-col justify-between items-center pt-10">
                <img src="./solutions.png" alt="solutions image" />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Solutions;
