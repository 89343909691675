import * as borsh from "borsh";
import { Keypair, PublicKey } from "@solana/web3.js";

// Actions
import { setLogs } from "../store/reducers/userSlice";

// Utils
import { getTokenUiAmount } from "./amount";

// Config
import { PROGRAM_ID, TARGET_TOKEN_ADDRESS } from "../config";

class Log {
  constructor(params) {
    this.operation_code = params.operation_code; // 0: burn, 1: transfer
    this.unix_timestamp = params.unix_timestamp;
    this.payer = params.payer;
    this.token = params.token;
    this.amount = params.amount;
  }
}

const logSchema = new Map([
  [
    Log,
    {
      kind: "struct",
      fields: [
        ["operation_code", "u8"],
        ["unix_timestamp", "u64"],
        ["payer", [32]],
        ["token", [32]],
        ["amount", "u64"],
      ],
    },
  ],
]);

// data mock for logSize calculations
const logValue = new Log({
  operation_code: 1,
  unix_timestamp: "1677146650",
  payer: new Keypair().publicKey.toBytes(),
  token: new Keypair().publicKey.toBytes(),
  amount: 123456789,
});
const logMsg = borsh.serialize(logSchema, logValue);

export const logSize = logMsg.length;

export const getLogs = () => async (dispatch, getState) => {
  const { connection, provider } = getState().connect;
  const publicKey = provider?.wallet?.publicKey;

  if (!connection || !provider || !publicKey) return;

  const { tokens, targetToken } = getState().user;

  const logs = await connection.getParsedProgramAccounts(new PublicKey(PROGRAM_ID), {
    filters: [
      { dataSize: logSize },
      {
        memcmp: {
          offset: 9,
          bytes: publicKey?.toString(),
        },
      },
    ],
  });

  const deserializedLogs = logs
    .map(logBytes => {
      const { operation_code, unix_timestamp, payer, token, amount } = borsh.deserialize(
        logSchema,
        Log,
        logBytes.account.data,
      );

      const date = new Date(unix_timestamp * 1000);
      const mintAddress = new PublicKey(token).toString();
      const isTargetToken = mintAddress === TARGET_TOKEN_ADDRESS[0];
      const tokenData = isTargetToken
        ? targetToken
        : tokens.find(token => token.mintAddress === mintAddress);
      const tokenDecimals = tokenData?.decimals;

      return {
        operationCode: operation_code,
        unixTimestamp: unix_timestamp.toString(),
        dateString: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`,
        payer: new PublicKey(payer).toString(),
        token: mintAddress,
        amount: getTokenUiAmount(amount.toString(), tokenDecimals ?? 0),
      };
    })
    .sort((a, b) => b.unixTimestamp - a.unixTimestamp);
  dispatch(setLogs(deserializedLogs));
};
