import React, { useEffect } from "react";
import styled from "styled-components";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

const CustomLoginButton = (props) => {
  useEffect(() => {}, []);

  const LoginButton = styled(WalletMultiButton)`
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-size: 21px;
    width: 168px !important;
    font-weight: 400 !important;
    font-family: courier !important;
    --tw-bg-opacity: 1 !important;
    background-color: rgb(29 29 31 / var(--tw-bg-opacity)) !important;
    color: #ffffff;
    border-radius: 1px !important;
    justify-content: center;
    height: 38px !important;
  `;

  return <LoginButton>{props.text}</LoginButton>;
};

export default CustomLoginButton;
