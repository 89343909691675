import "@solana/wallet-adapter-react-ui/styles.css";
import "./styles/globals.scss";
import react, { useState, useEffect } from "react";

import Home from "./components/Home";
import Flow from "./components/Flow";
import About from "./components/About";
import MobileMenu from "./components/MobileMenu";
import Solutions from "./components/Solutions";
import Footer from "./components/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import * as buffer from "buffer";
window.Buffer = buffer.Buffer;

// Components
import Header from "./components/Header/Header";

// Hooks
import { useInitialization } from "./hooks/useInitialization";

const App = () => {
  useInitialization();

  const [mobileMenu, showMobileMenu] = useState(false);
  const [isLoggedIn, logIn] = useState(false);

  const pageName = window.location.pathname;

  return (
    <>
      <div className="flex flex-col w-full min-h-screen transition-colors ease-in-out duration-700 bg-lightBackground font-primary">
        <Header showMobileMenu={showMobileMenu} />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/about" element={<About />} />
            <Route path="/draw" element={<Flow />} />
            <Route path="draw/*" element={<Flow />} />
            <Route path="flow" element={<Flow />} />
          </Routes>
        </BrowserRouter>
        {pageName.length !== 1 ? <Footer /> : <></>};
      </div>
      <div id="modal-portal">
        {mobileMenu ? <MobileMenu showMobileMenu={showMobileMenu} /> : <></>};
      </div>
    </>
  );
};

export default App;
