import * as borsh from "borsh";
import { TransactionInstruction, SYSVAR_RENT_PUBKEY, PublicKey } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";

// utils
import { getSolRawAmount, getTokenRawAmount } from "./amount";

// Config
import { PROGRAM_ID, TARGET_TOKEN_ADDRESS } from "../config";

class Params {
  constructor(params) {
    this.burn = params.burn;
    this.amounts = params.amounts;
  }
}

const paramsSchema = new Map([
  [
    Params,
    {
      kind: "struct",
      fields: [
        ["burn", "u8"],
        ["amounts", ["u64"]],
      ],
    },
  ],
]);

export const createProgramInstruction = ({
  payerPk,
  transferData,
  payerTokenTargetAccountPk,
  logKeypairTokenTargetPk,
  isAddTargetToken,
  targetTokenAddress,
}) => {
  const amounts = transferData.map(({ amount, decimals, isNativeSol }) =>
    isNativeSol ? getSolRawAmount(amount) : getTokenRawAmount(amount, decimals),
  );

  const value = new Params({ amounts, burn: Number(isAddTargetToken) });
  const data = borsh.serialize(paramsSchema, value);

  const baseKeys = [
    // commmon
    { pubkey: payerPk, isSigner: true, isWritable: false },
    { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
    { pubkey: SYSVAR_RENT_PUBKEY, isSigner: false, isWritable: false },
    // targetToken
    ...(isAddTargetToken
      ? [
          { pubkey: new PublicKey(targetTokenAddress), isSigner: false, isWritable: true },
          { pubkey: payerTokenTargetAccountPk, isSigner: false, isWritable: true },
          { pubkey: logKeypairTokenTargetPk, isSigner: false, isWritable: true },
        ]
      : []),
  ];

  const keys = transferData.reduce(
    (acc, { payerTokenAccountPk, receiverTokenAccountPk, logKeyPair }) => {
      const transferKeysGroup = [
        { pubkey: payerTokenAccountPk, isSigner: false, isWritable: true },
        { pubkey: receiverTokenAccountPk, isSigner: false, isWritable: true },
        { pubkey: logKeyPair.publicKey, isSigner: false, isWritable: true },
      ];
      acc.push(...transferKeysGroup);

      return acc;
    },
    baseKeys,
  );

  return new TransactionInstruction({
    programId: PROGRAM_ID,
    keys,
    data,
  });
};
