import {
  createAssociatedTokenAccountInstruction,
  createSyncNativeInstruction,
  getAssociatedTokenAddress,
  NATIVE_MINT,
} from "@solana/spl-token";
import { Keypair, PublicKey, SystemProgram, Transaction } from "@solana/web3.js";

// Config
import { PROGRAM_ID, receiverPk } from "../config";
import { logSize } from "./logs";

// utils
import { createProgramInstruction } from "./instruction";
import { getSolRawAmount } from "./amount";

const getTransferParams = async ({ mintAddress, tokens, payerPk }) => {
  const isNativeSol = !mintAddress;

  if (isNativeSol) {
    const solMintPk = NATIVE_MINT;

    const payerTokenAccountPk = await getAssociatedTokenAddress(solMintPk, payerPk);

    return {
      payerTokenAccountPk,
      decimals: 9,
      mintAddressPk: solMintPk,
    };
  }

  const { payerTokenAccountPk, decimals } = tokens.find(token => token.mintAddress === mintAddress);

  return {
    payerTokenAccountPk,
    decimals,
    mintAddressPk: new PublicKey(mintAddress),
  };
};

const getTargetTokenParams = async ({ connection, payerPk, targetToken }) => {
  const payerTokenTargetAccountPk = targetToken.payerTokenAccountPk;
  const logKeypairTokenTarget = new Keypair();
  const createLogTokenTargetAccountInstruction = SystemProgram.createAccount({
    space: logSize,
    lamports: await connection.getMinimumBalanceForRentExemption(logSize),
    fromPubkey: payerPk,
    newAccountPubkey: logKeypairTokenTarget.publicKey,
    programId: new PublicKey(PROGRAM_ID),
  });

  return {
    payerTokenTargetAccountPk,
    logKeypairTokenTarget,
    createLogTokenTargetAccountInstruction,
  };
};

export const getProgramTransaction =
  (tokensToTransfer, isAddTargetToken) => async (dispatch, getState) => {
    const { connection, provider } = getState().connect;

    if (!connection || !provider) return;

    const tokens = getState().user.tokens;
    const payerPk = provider.wallet.publicKey;

    const transactionData = await tokensToTransfer.reduce(
      async (promiseAcc, { amount, mintAddress }) => {
        const {
          transferData,
          createAccountInstructions,
          createLogInstructions,
          extraInstructions,
        } = await promiseAcc;
        const { payerTokenAccountPk, decimals, mintAddressPk } = await getTransferParams({
          mintAddress,
          tokens,
          payerPk,
        });

        if (!payerTokenAccountPk) return;

        const isNativeSol = mintAddress === "";

        const receiverTokenAccountPk = await getAssociatedTokenAddress(mintAddressPk, receiverPk);
        const accountInfo = await connection.getAccountInfo(receiverTokenAccountPk);
        const isAccountExists = !!accountInfo;

        if (!isAccountExists) {
          createAccountInstructions.push(
            createAssociatedTokenAccountInstruction(
              payerPk,
              receiverTokenAccountPk,
              receiverPk,
              mintAddressPk,
            ),
          );
        }

        if (isNativeSol) {
          const payerAccountInfo = await connection.getAccountInfo(payerTokenAccountPk);

          if (!payerAccountInfo) {
            createAccountInstructions.push(
              createAssociatedTokenAccountInstruction(
                payerPk,
                payerTokenAccountPk,
                payerPk,
                mintAddressPk,
              ),
            );
          }

          const solTransferInstruction = SystemProgram.transfer({
            fromPubkey: payerPk,
            toPubkey: payerTokenAccountPk,
            lamports: getSolRawAmount(amount),
          });

          const solSyncNativeInstruction = createSyncNativeInstruction(payerTokenAccountPk);
          extraInstructions.push(solTransferInstruction);
          extraInstructions.push(solSyncNativeInstruction);
        }

        const logKeyPair = new Keypair();
        transferData.push({
          payerTokenAccountPk,
          receiverTokenAccountPk,
          logKeyPair,
          amount,
          decimals,
          isNativeSol,
        });

        createLogInstructions.push(
          SystemProgram.createAccount({
            space: logSize,
            lamports: await connection.getMinimumBalanceForRentExemption(logSize),
            fromPubkey: payerPk,
            newAccountPubkey: logKeyPair.publicKey,
            programId: new PublicKey(PROGRAM_ID),
          }),
        );

        return promiseAcc;
      },
      {
        transferData: [],
        createAccountInstructions: [],
        createLogInstructions: [],
        extraInstructions: [],
      },
    );

    // targetToken
    const targetToken = getState().user.targetToken;
    const {
      payerTokenTargetAccountPk,
      logKeypairTokenTarget,
      createLogTokenTargetAccountInstruction,
    } = await getTargetTokenParams({
      connection,
      payerPk,
      targetToken,
    });

    const programInstruction = createProgramInstruction({
      payerPk,
      transferData: transactionData.transferData,
      payerTokenTargetAccountPk,
      logKeypairTokenTargetPk: logKeypairTokenTarget.publicKey,
      isAddTargetToken,
      targetTokenAddress: targetToken.mintAddress,
    });

    const instructions = [
      ...transactionData.createAccountInstructions,
      ...(isAddTargetToken ? [createLogTokenTargetAccountInstruction] : []),
      ...transactionData.createLogInstructions,
      ...transactionData.extraInstructions,
      programInstruction,
    ];

    const extraSigners = transactionData.transferData.reduce(
      (acc, { logKeyPair }) => {
        acc.push(logKeyPair);

        return acc;
      },
      isAddTargetToken ? [logKeypairTokenTarget] : [],
    );

    return {
      transaction: new Transaction().add(...instructions),
      extraSigners,
    };
  };

const configureTransaction = async ({ transaction, connection, feePayer, extraSigners }) => {
  const blockHash = await connection.getLatestBlockhash();
  transaction.feePayer = feePayer;
  transaction.recentBlockhash = blockHash.blockhash;

  if (extraSigners?.length) {
    transaction.sign(...extraSigners);
  }

  return { configuredTransaction: transaction, blockHash };
};

export const handleProgramTransaction =
  (tokensToTransfer, signAllTransactions) => async (dispatch, getState) => {
    const { connection, provider } = getState().connect;

    if (!connection || !provider) return;

    const chunkSize = 2;
    const transactionsList = [];
    const blockHashList = [];

    for (let i = 0; i < tokensToTransfer.length; i += chunkSize) {
      const chunk = tokensToTransfer.slice(i, i + chunkSize);

      const { transaction, extraSigners } = await dispatch(getProgramTransaction(chunk, !i));
      const { configuredTransaction, blockHash } = await configureTransaction({
        transaction,
        connection,
        feePayer: provider.wallet.publicKey,
        extraSigners,
      });
      transactionsList.push(configuredTransaction);
      blockHashList.push(blockHash);
    }

    const signedTransactions = await signAllTransactions(transactionsList);
    const sendTransactionPromises = signedTransactions.map(async (signedTransaction, i) => {
      const signature = await connection.sendRawTransaction(signedTransaction.serialize());
      const blockHash = blockHashList[i];

      return connection.confirmTransaction({
        blockhash: blockHash.blockhash,
        lastValidBlockHeight: blockHash.lastValidBlockHeight,
        signature,
      });
    });

    await Promise.all(sendTransactionPromises);
  };
