import * as yup from "yup";

// utils
import { getMintAddressFromPath, getTokenNameFromMint } from "../../utils/common";

// Config
import { SOL_MAX_DELTA } from "../../config";

export const TOKENS_TABLE_FIELDS = {
  //  amount of sol or token (balance)
  amount: "amount",
  //  whether token will be added to transaction
  checkbox: "isActive",
};

const baseAmountInputSchema = yup
  .number()
  .transform((value, rawValue) => (rawValue === "" ? undefined : value))
  .required()
  .min(0);

export const createYupSchema = tokens => {
  const fieldsShape = tokens.reduce(
    (acc, { mintAddress }) => {
      acc[mintAddress] = yup.object({
        [TOKENS_TABLE_FIELDS.amount]: yup.mixed().when(TOKENS_TABLE_FIELDS.checkbox, {
          is: true,
          then: () =>
            baseAmountInputSchema
              .label(`${getTokenNameFromMint(mintAddress)} amount`)
              .test("Balance", "Not enough token balance", (value, context) => {
                const mintAddress = getMintAddressFromPath(context.path);
                const tokenData = context.options.context.tokens.find(
                  token => token.mintAddress === mintAddress,
                );

                if (!tokenData) return true;

                return value <= tokenData.tokenBalance;
              }),
        }),
      });

      return acc;
    },
    {
      sol: yup.object({
        amount: baseAmountInputSchema.label("Sol amount").test("Balance", (value, context) => {
          const solBalance = context.options.context.solBalance;

          if (value > solBalance) {
            return context.createError({
              message: `You currently have just ${solBalance.toFixed(2)} SOL in your wallet. Please select a value that is within your available balance.`,
              path: "sol.amount",
            });
          }

          if (solBalance < SOL_MAX_DELTA) {
            return context.createError({
              message: `SOL balance should be greater than 1`,
              path: "sol.amount",
            });
          }

          return true;
        }),
      }),
    },
  );

  return yup.object().shape(fieldsShape);
};
