import React from "react";

const About = () => {
  return (
    <>
      <main className="relative lg:mt-4 w-full flex-1 mx-auto overflow-x-hidden max-w-[1400px] false">
        <div className="flex flex-col items-center justify-start h-full px-4 overflow-x-hidden pt-6">
          <div className="mb-10 sm:my-16 heightSmall:my-3 h-full w-full px-4 overflow-x-hidden pt-10">
            <div className="flex flex-col lg:flex-row items-center justify-center gap-10 lg:gap-32">
              <div className="flex-col max-w-[500px]">
                <div className="flex justify-center lg:justify-start">
                  <img
                    alt="reward icon"
                    src="https://powered.by.dustlabs.com/cdn-cgi/image/width=48/https://e99de883.os-4cs.pages.dev/y00ts//y00t-point.svg"
                    width="22"
                    height="24.86"
                  />
                </div>
                <div className="max-w-[500px] flex flex-col justify-center items-center lg:items-start !w-full !text-left">
                  <div>
                    <h2 className="text-center text-lightFontPrimary py-2 !text-[20px] md:!text-2xl leading-[6px] lg:leading-3">
                      y00tLabs
                    </h2>
                  </div>
                  <div>
                    <h2 className="text-center text-lightFontPrimary py-2 !text-[26px] md:!text-5xl font-primaryBold whitespace-nowrap">
                      Why Draw?
                    </h2>
                  </div>
                  <div>
                    <p className="text-[16px] md:text-[19px] leading-[22px] font-primary text-justify md:leading-[25.3px]">
                      Draw - an experiment that is set to accelerate cross-chain collaboration. The
                      goal is to build y00topia into the #1 Web3 hub where the collective genius is
                      leveraged to build the communities, brands, and art of the future.
                    </p>
                    <p className="text-[16px] mt-6 md:text-[19px] leading-[22px] font-primary text-justify md:leading-[25.3px]">
                      y00tLabs has nominated a diverse range of communities to participate in Draw,
                      each of which has been granted 500 bl00ps that can draw 3 y00ts.
                    </p>
                    <p className="text-[16px] mt-6 md:text-[19px] leading-[22px] font-primary text-justify md:leading-[25.3px]">
                      Winners will have the opportunity to connect in y00topia with founders and
                      visionaries who are committed to driving cross-chain development in the NFT
                      space.
                    </p>
                    <p className="text-[16px] mt-6 md:text-[19px] leading-[22px] font-primary text-justify md:leading-[25.3px]">
                      The funds generated from Draw will be used to further finance initiatives that
                      encourage cross-chain growth and volume, fostering a future marked by
                      collaboration and innovation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-[282px] h-[375px] false border-2 border-lightBorder flex flex-col justify-between items-center pt-10">
                <img src="./solutions.png" alt="solutions image" />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default About;
