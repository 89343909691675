import { Slider } from "antd";
import React, { useEffect } from "react";

// Components
import ErrorMessage from "../../Input/ErrorMessage/ErrorMessage";

// Config
import { TOKENS_TABLE_FIELDS } from "../validationSchema";

// Styles
import { useFormContext } from "react-hook-form";
import "./tailwind-slider.css";

const SolTableRow = ({ setSuccessRate }) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    const initialValue = 6;

    const timer = setTimeout(() => {
      setValue(`sol.${TOKENS_TABLE_FIELDS.amount}`, initialValue);
      handleSliderChange(initialValue);
    }, 500);

    return () => clearTimeout(timer);
  }, [setValue]);


  const handleSliderChange = value => {
    const successRateMapping = {
      1: 5,
      2: 12,
      3: 18,
      4: 24,
      5: 30,
      6: 34,
      7: 42,
      8: 50,
      9: 54,
      10: 58,
    };

    setValue(`sol.${TOKENS_TABLE_FIELDS.amount}`, value);
    setSuccessRate(successRateMapping[value]);
  };

  const marks = {
    1: (
      <div>
        <div>1</div>
        <div className="hidden md:block text-xs text-gray-500">SOL</div>
      </div>
    ),
    2: (
      <div>
        <div>2</div>
        <div className="hidden md:block text-xs text-gray-500">SOL</div>
      </div>
    ),
    3: (
      <div>
        <div>3</div>
        <div className="hidden md:block text-xs text-gray-500">SOL</div>
      </div>
    ),
    4: (
      <div>
        <div>4</div>
        <div className="hidden md:block text-xs text-gray-500">SOL</div>
      </div>
    ),
    5: (
      <div>
        <div>5</div>
        <div className="hidden md:block text-xs text-gray-500">SOL</div>
      </div>
    ),
    6: (
      <div>
        <div>6</div>
        <div className="hidden md:block text-xs text-gray-500">SOL</div>
      </div>
    ),
    7: (
      <div>
        <div>7</div>
        <div className="hidden md:block text-xs text-gray-500">SOL</div>
      </div>
    ),
    8: (
      <div>
        <div>8</div>
        <div className="hidden md:block text-xs text-gray-500">SOL</div>
      </div>
    ),
    9: (
      <div>
        <div>9</div>
        <div className="hidden md:block text-xs text-gray-500">SOL</div>
      </div>
    ),
    10: (
      <div>
        <div>10</div>
        <div className="hidden md:block text-xs text-gray-500">SOL</div>
      </div>
    ),
  };

  return (
    <div className="flex flex-col items-center">
      <div className="mt-10 w-full md:w-[600px] px-4 md:px-0">
        <div className="relative w-full">
          <Slider
            className="tw-slider w-full"
            name={`sol.${TOKENS_TABLE_FIELDS.amount}`}
            min={1}
            max={10}
            step={1}
            defaultValue={6}
            onChange={handleSliderChange}
            marks={marks}
          />
        </div>
      </div>
      <div className="flex justify-center mt-8 mb-4">
        <ErrorMessage errors={errors} name={`sol.${TOKENS_TABLE_FIELDS.amount}`} />
      </div>
    </div>
  );
  
  



};

export default SolTableRow;

