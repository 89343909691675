import { useEffect } from "react";
import CustomLoginButton from "./CustomLoginButton";
import HeaderWalletButton from "./HeaderWalletButton";
import { useWallet } from "@solana/wallet-adapter-react";

const Header = props => {
  const pageName = window.location.pathname;
  const { connected } = useWallet();
  useEffect(() => {
    const showMoreButton = document.querySelector("#show-more-button");
    const showMoreMenu = document.querySelector("#show-more-menu");
    showMoreButton?.addEventListener("mouseover", () => {
      showMoreMenu?.classList.remove("hidden");
    });
    showMoreMenu?.addEventListener("mouseleave", () => {
      showMoreMenu?.classList.add("hidden");
    });
  }, []);

  return (
    <>
      {/*  <header className={styles.header}>
        <h1 className={styles.title}>SOLANA</h1>
        <div className={styles.connectionBlock}>
          <BalanceBlock />
          <WalletMultiButton className={styles.connectButton} />
        </div>
      </header> */}
      <nav className="hidden lg:block w-full px-24 absolute top-0 z-50">
        <div className="h-[77px] w-full flex pt-4 pb-1 justify-between items-center border-solid border-b border-color-black overflow-visible">
          <div className="w-[168px] flex items-center">
            <a className="w-[39px] flex items-center" href="/">
              <img
                alt="site logo"
                className="cursor-pointer"
                src="https://powered.by.dustlabs.com/cdn-cgi/image/width=64/https://8739e0e7.os-4cs.pages.dev/y00ts/y00ts-00.svg"
                width="32"
                height="44"
              ></img>
            </a>
          </div>
          <div className="hidden"></div>
          <div className="relative flex items-center justify-between py-2 z-50 px-4">
            {/*  <a className="flex flex-row justify-center items-center cursor-pointer p-2 xl:p-4" href="/mint">
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="fill-lightFontUnselected"><path d="M12 22.7501C6.92003 22.7501 2.49003 19.1401 1.47003 14.1701C1.39003 13.7601 1.65003 13.3701 2.05003 13.2801C2.46003 13.2001 2.85003 13.4601 2.94003 13.8601C3.82003 18.1401 7.63003 21.2501 12 21.2501C16.36 21.2501 20.17 18.1601 21.06 13.9001C21.14 13.4901 21.54 13.2301 21.95 13.3201C22.36 13.4001 22.62 13.8001 22.53 14.2101C21.49 19.1501 17.07 22.7501 12 22.7501Z"></path><path d="M21.81 10.8102C21.46 10.8102 21.15 10.5603 21.08 10.2003C20.23 5.88026 16.41 2.74023 12 2.74023C7.61995 2.74023 3.80996 5.85025 2.93996 10.1302C2.85996 10.5402 2.45996 10.7902 2.04996 10.7102C1.63996 10.6302 1.37996 10.2303 1.46996 9.82025C2.48996 4.85025 6.91995 1.24023 12 1.24023C17.13 1.24023 21.56 4.89025 22.55 9.91025C22.63 10.3202 22.36 10.7103 21.96 10.7903C21.91 10.8103 21.86 10.8102 21.81 10.8102Z"></path><path d="M12 14.25C10.76 14.25 9.75 13.24 9.75 12C9.75 10.76 10.76 9.75 12 9.75C13.24 9.75 14.25 10.76 14.25 12C14.25 13.24 13.24 14.25 12 14.25ZM12 11.25C11.59 11.25 11.25 11.59 11.25 12C11.25 12.41 11.59 12.75 12 12.75C12.41 12.75 12.75 12.41 12.75 12C12.75 11.59 12.41 11.25 12 11.25Z"></path></svg>
                <span className="block text-sm whitespace-nowrap ml-2 select-none font-primary">Mint</span>
              </a> */}
            <a
              className="flex flex-row justify-center items-center cursor-pointer p-2 xl:p-4"
              href="/"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                className="fill-lightFontUnselected"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.8">
                  <path d="M16.7004 19.73H7.30042C6.56042 19.73 5.81042 19.2 5.56042 18.51L1.42042 6.91998C0.910423 5.45998 1.28042 4.78998 1.68042 4.48998C2.08042 4.18998 2.83042 4.00998 4.09042 4.90998L7.99042 7.69998C8.11042 7.76998 8.22042 7.79998 8.30042 7.77998C8.39042 7.74998 8.46042 7.66998 8.51042 7.52998L10.2704 2.83998C10.8004 1.43998 11.5804 1.22998 12.0004 1.22998C12.4204 1.22998 13.2004 1.43998 13.7304 2.83998L15.4904 7.52998C15.5404 7.65998 15.6104 7.74998 15.7004 7.77998C15.7904 7.80998 15.9004 7.77998 16.0104 7.68998L19.6704 5.07998C21.0104 4.11998 21.7904 4.30998 22.2204 4.61998C22.6404 4.93998 23.0304 5.64998 22.4804 7.19998L18.4404 18.51C18.1904 19.2 17.4404 19.73 16.7004 19.73ZM2.68042 5.80998C2.70042 5.94998 2.74042 6.14998 2.84042 6.40998L6.98042 18C7.02042 18.1 7.20042 18.23 7.30042 18.23H16.7004C16.8104 18.23 16.9904 18.1 17.0204 18L21.0604 6.69998C21.2004 6.31998 21.2404 6.05998 21.2504 5.90998C21.1004 5.95998 20.8704 6.06998 20.5404 6.30998L16.8804 8.91998C16.3804 9.26998 15.7904 9.37998 15.2604 9.21998C14.7304 9.05998 14.3004 8.63998 14.0804 8.06998L12.3204 3.37998C12.1904 3.02998 12.0704 2.85998 12.0004 2.77998C11.9304 2.85998 11.8104 3.02998 11.6804 3.36998L9.92042 8.05998C9.71042 8.62998 9.28042 9.04998 8.74042 9.20998C8.21042 9.36998 7.61042 9.25998 7.12042 8.90998L3.22042 6.11998C2.99042 5.95998 2.81042 5.85998 2.68042 5.80998Z"></path>
                  <path d="M17.5 22.75H6.5C6.09 22.75 5.75 22.41 5.75 22C5.75 21.59 6.09 21.25 6.5 21.25H17.5C17.91 21.25 18.25 21.59 18.25 22C18.25 22.41 17.91 22.75 17.5 22.75Z"></path>
                  <path d="M14.5 14.75H9.5C9.09 14.75 8.75 14.41 8.75 14C8.75 13.59 9.09 13.25 9.5 13.25H14.5C14.91 13.25 15.25 13.59 15.25 14C15.25 14.41 14.91 14.75 14.5 14.75Z"></path>
                </g>
              </svg>
              <span
                className={`block text-sm whitespace-nowrap ml-2 select-none ${
                  pageName.length === 1 ? "font-primaryBold" : "font-primary"
                }`}
              >
                Labs
              </span>
            </a>
            <a
              className="flex flex-row justify-center items-center cursor-pointer p-2 xl:p-4"
              href="/solutions"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-lightFontUnselected"
              >
                <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 9.64 2 7.4 3.42 5.52C3.67 5.19 4.14 5.13 4.47 5.38C4.8 5.63 4.87 6.1 4.62 6.43C3.4 8.04 2.75 9.97 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75C11.59 2.75 11.25 2.41 11.25 2C11.25 1.59 11.59 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75Z"></path>
                <path d="M12 19.75C7.73 19.75 4.25 16.27 4.25 12C4.25 11.59 4.59 11.25 5 11.25C5.41 11.25 5.75 11.59 5.75 12C5.75 15.45 8.55 18.25 12 18.25C15.45 18.25 18.25 15.45 18.25 12C18.25 8.55 15.45 5.75 12 5.75C11.59 5.75 11.25 5.41 11.25 5C11.25 4.59 11.59 4.25 12 4.25C16.27 4.25 19.75 7.73 19.75 12C19.75 16.27 16.27 19.75 12 19.75Z"></path>
                <path d="M12 16.75C11.59 16.75 11.25 16.41 11.25 16C11.25 15.59 11.59 15.25 12 15.25C13.79 15.25 15.25 13.79 15.25 12C15.25 10.21 13.79 8.75 12 8.75C11.59 8.75 11.25 8.41 11.25 8C11.25 7.59 11.59 7.25 12 7.25C14.62 7.25 16.75 9.38 16.75 12C16.75 14.62 14.62 16.75 12 16.75Z"></path>
              </svg>
              <span
                className={`block text-sm whitespace-nowrap ml-2 select-none ${
                  pageName.includes("solutions") ? "font-primaryBold" : "font-primary"
                }`}
              >
                Solutions
              </span>
            </a>
            <a
              className="flex flex-row justify-center items-center cursor-pointer p-2 xl:p-4"
              href="/draw"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-lightFontUnselected"
              >
                <path d="M15.59 13.01C12.35 13.01 9.70996 10.37 9.70996 7.13C9.70996 3.89 12.35 1.25 15.59 1.25C18.83 1.25 21.47 3.89 21.47 7.13C21.47 10.37 18.83 13.01 15.59 13.01ZM15.59 2.75C13.18 2.75 11.21 4.71 11.21 7.13C11.21 9.55 13.17 11.51 15.59 11.51C18.01 11.51 19.97 9.55 19.97 7.13C19.97 4.71 18 2.75 15.59 2.75Z"></path>
                <path d="M6.36026 20.19C4.25026 20.19 2.53027 18.47 2.53027 16.36C2.53027 14.25 4.25026 12.53 6.36026 12.53C8.47026 12.53 10.1903 14.25 10.1903 16.36C10.1903 18.47 8.47026 20.19 6.36026 20.19ZM6.36026 14.03C5.08026 14.03 4.03027 15.07 4.03027 16.36C4.03027 17.64 5.07026 18.69 6.36026 18.69C7.64026 18.69 8.69028 17.65 8.69028 16.36C8.69028 15.08 7.64026 14.03 6.36026 14.03Z"></path>
                <path d="M16.6201 22.75C14.7901 22.75 13.3101 21.26 13.3101 19.44C13.3101 17.61 14.8001 16.13 16.6201 16.13C18.4401 16.13 19.9301 17.62 19.9301 19.44C19.9301 21.26 18.4401 22.75 16.6201 22.75ZM16.6201 17.62C15.6201 17.62 14.8101 18.43 14.8101 19.43C14.8101 20.43 15.6201 21.24 16.6201 21.24C17.6201 21.24 18.4301 20.43 18.4301 19.43C18.4301 18.44 17.6201 17.62 16.6201 17.62Z"></path>
              </svg>
              <span
                className={`block text-sm whitespace-nowrap ml-2 select-none ${
                  pageName.includes("draw") || pageName.includes("about")
                    ? "font-primaryBold"
                    : "font-primary"
                }`}
              >
                Draw
              </span>
            </a>
            <a
              className="flex flex-row justify-center items-center cursor-pointer p-2 xl:p-4"
              target="_blank"
              rel="noopener noreferrer"
              href="https://lu.ma/DeLabs-Meetups"
            >
              <svg
                width="24"
                height="24"
                className="fill-lightFontUnselected"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 5.75C7.59 5.75 7.25 5.41 7.25 5V2C7.25 1.59 7.59 1.25 8 1.25C8.41 1.25 8.75 1.59 8.75 2V5C8.75 5.41 8.41 5.75 8 5.75Z"></path>
                <path d="M16 5.75C15.59 5.75 15.25 5.41 15.25 5V2C15.25 1.59 15.59 1.25 16 1.25C16.41 1.25 16.75 1.59 16.75 2V5C16.75 5.41 16.41 5.75 16 5.75Z"></path>
                <path d="M15 11.75H7C6.59 11.75 6.25 11.41 6.25 11C6.25 10.59 6.59 10.25 7 10.25H15C15.41 10.25 15.75 10.59 15.75 11C15.75 11.41 15.41 11.75 15 11.75Z"></path>
                <path d="M12 15.75H7C6.59 15.75 6.25 15.41 6.25 15C6.25 14.59 6.59 14.25 7 14.25H12C12.41 14.25 12.75 14.59 12.75 15C12.75 15.41 12.41 15.75 12 15.75Z"></path>
                <path d="M15 22.75H9C3.38 22.75 2.25 20.1 2.25 15.82V9.65C2.25 4.91 3.85 2.98 7.96 2.75H16C20.15 2.98 21.75 4.91 21.75 9.65V16C21.75 16.41 21.41 16.75 21 16.75C20.59 16.75 20.25 16.41 20.25 16V9.65C20.25 5.29 18.8 4.41 15.96 4.25H8C5.2 4.41 3.75 5.29 3.75 9.65V15.82C3.75 19.65 4.48 21.25 9 21.25H15C15.41 21.25 15.75 21.59 15.75 22C15.75 22.41 15.41 22.75 15 22.75Z"></path>
                <path d="M15 22.75C14.9 22.75 14.81 22.73 14.71 22.69C14.43 22.57 14.25 22.3 14.25 22V19C14.25 16.58 15.58 15.25 18 15.25H21C21.3 15.25 21.58 15.43 21.69 15.71C21.81 15.99 21.74 16.31 21.53 16.53L15.53 22.53C15.39 22.67 15.2 22.75 15 22.75ZM18 16.75C16.42 16.75 15.75 17.42 15.75 19V20.19L19.19 16.75H18Z"></path>
              </svg>
              <span className="block text-sm whitespace-nowrap ml-2 select-none font-primary">
                Events
              </span>
            </a>
            <div
              id="show-more-button"
              className="lg:flex flex-col sm:flex-row justify-center items-center cursor-pointer p-2 xl:p-4"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-lightFontUnselected"
              >
                <path d="M17.5401 9.06001C15.7701 9.06001 14.3301 7.62001 14.3301 5.85001C14.3301 4.08001 15.7701 2.64001 17.5401 2.64001C19.3101 2.64001 20.7501 4.08001 20.7501 5.85001C20.7501 7.62001 19.3101 9.06001 17.5401 9.06001ZM17.5401 4.13001C16.6001 4.13001 15.8301 4.90001 15.8301 5.84001C15.8301 6.78001 16.6001 7.55001 17.5401 7.55001C18.4801 7.55001 19.2501 6.78001 19.2501 5.84001C19.2501 4.90001 18.4801 4.13001 17.5401 4.13001Z"></path>
                <path d="M6.46001 9.06001C4.69001 9.06001 3.25 7.62001 3.25 5.85001C3.25 4.08001 4.69001 2.64001 6.46001 2.64001C8.23001 2.64001 9.67 4.08001 9.67 5.85001C9.67 7.62001 8.23001 9.06001 6.46001 9.06001ZM6.46001 4.13001C5.52001 4.13001 4.75 4.90001 4.75 5.84001C4.75 6.78001 5.52001 7.55001 6.46001 7.55001C7.40001 7.55001 8.17 6.78001 8.17 5.84001C8.17 4.90001 7.41001 4.13001 6.46001 4.13001Z"></path>
                <path d="M17.5401 21.37C15.7701 21.37 14.3301 19.93 14.3301 18.16C14.3301 16.39 15.7701 14.95 17.5401 14.95C19.3101 14.95 20.7501 16.39 20.7501 18.16C20.7501 19.93 19.3101 21.37 17.5401 21.37ZM17.5401 16.44C16.6001 16.44 15.8301 17.21 15.8301 18.15C15.8301 19.09 16.6001 19.86 17.5401 19.86C18.4801 19.86 19.2501 19.09 19.2501 18.15C19.2501 17.21 18.4801 16.44 17.5401 16.44Z"></path>
                <path d="M6.46001 21.37C4.69001 21.37 3.25 19.93 3.25 18.16C3.25 16.39 4.69001 14.95 6.46001 14.95C8.23001 14.95 9.67 16.39 9.67 18.16C9.67 19.93 8.23001 21.37 6.46001 21.37ZM6.46001 16.44C5.52001 16.44 4.75 17.21 4.75 18.15C4.75 19.09 5.52001 19.86 6.46001 19.86C7.40001 19.86 8.17 19.09 8.17 18.15C8.17 17.21 7.41001 16.44 6.46001 16.44Z"></path>
              </svg>
              <span className="text-sm whitespace-nowrap sm:ml-2 font-primary">More</span>
              <div className="absolute right-0 w-fit bg-transparent mx-auto h-fit top-[50px]">
                <div
                  id="show-more-menu"
                  className="hidden border rounded-xl rounded-none border border-black bg-white shadow-lg text-lightFontPrimary p-6 z-50"
                >
                  <div className="hidden block absolute right-[32px] -top-[9px]">
                    <svg
                      width="13"
                      height="9"
                      viewBox="0 0 13 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.977878 8.5L6.5 0.853985L12.0221 8.5H0.977878Z"
                        fill="black"
                        stroke="black"
                      ></path>
                    </svg>
                  </div>
                  <div className="flex flex-row items-start justify-between gap-6 w-full text-[13px]">
                    <ul className="min-w-[200px]">
                      <div className="mt-1 hover:opacity-70 group pb-6 last:pb-0">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://polygon.technology"
                        >
                          <li className="text-[13px]">
                            <span className="flex items-center gap-2 mb-2 group-hover:underline">
                              <img width="24" src="/matic-logo.png" />
                              Polygon
                            </span>
                            <p className="text-[10px] text-fontSecondary">
                              Bringing the World to Ethereum
                            </p>
                          </li>
                        </a>
                      </div>
                      <div className="mt-1 hover:opacity-70 group pb-6 last:pb-0">
                        <a target="_blank" rel="noopener noreferrer" href="https://delabs.xyz">
                          <li className="text-[13px]">
                            <span className="flex items-center gap-2 mb-2 group-hover:underline">
                              <img width="24" src="/DeLabs.svg" />
                              DeLabs
                            </span>
                            <p className="text-[10px] text-fontSecondary">
                              The best membership clubs on the internet
                            </p>
                          </li>
                        </a>
                      </div>
                    </ul>
                    <ul className="min-w-[200px]">
                      <div className="mt-1 hover:opacity-70 group pb-6 last:pb-0">
                        <a target="_blank" rel="noopener noreferrer" href="https://dustlabs.com">
                          <li className="text-[13px]">
                            <span className="flex items-center gap-2 mb-2 group-hover:underline">
                              <img width="14" src="/dustdust.png" />
                              Dust Labs
                            </span>
                            <p className="text-[10px] text-fontSecondary">
                              Building tools and communities
                            </p>
                          </li>
                        </a>
                      </div>
                      <div className="mt-1 hover:opacity-70 group pb-6 last:pb-0">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://dustprotocol.com"
                        >
                          <li className="text-[13px]">
                            <span className="flex items-center mb-2 group-hover:underline">
                              <img width="29" src="/dust-protocol.png" />
                              Dust Protocol
                            </span>
                            <p className="text-[10px] text-fontSecondary">A $DUST Experiment</p>
                          </li>
                        </a>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-auto z-20">
            {connected ? (
              <div className="wallet-adapter-dropdown">
                <HeaderWalletButton />
              </div>
            ) : (
              <div className="invisible h-12 w-[164px]"></div>
            )}
          </div>
        </div>
      </nav>
      <div className="h-[73px] lg:hidden w-full px-5 sm:px-10 py-3 flex justify-between border-b border-lightBorder z-50 mb-10">
        <div className="w-8 flex items-center">
          <a className="w-[39px] flex items-center" href="/">
            <img
              alt="Site Logo"
              src="https://powered.by.dustlabs.com/cdn-cgi/image/width=64/https://8739e0e7.os-4cs.pages.dev/y00ts/y00ts-00.svg"
              width="32"
              height="44"
              className="cursor-pointer"
            />
          </a>
        </div>
        <div className="z-20 flex items-center visible lg:invisible">
          <HeaderWalletButton />
        </div>
        <button
          id="mobile-button"
          className="w-10 p-1 flex items-center hover:cursor-pointer"
          onClick={() => props.showMobileMenu(true)}
        >
          <img
            alt="Menu"
            src="https://powered.by.dustlabs.com/cdn-cgi/image/width=96/https://48a0ce66.os-4cs.pages.dev/shared/icons/burger-menu.svg"
            width="44"
            height="44"
          />
        </button>
      </div>
    </>
  );
};

export default Header;
