import React from "react";
import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { ConfigProvider } from "antd";
import SolanaProvider from "./components/SolanaProvider/SolanaProvider";
import App from "./App";
import './export.css';
import { store } from "./store";
import { THEME_OPTIONS } from "./config/theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <SolanaProvider>
        <ConfigProvider theme={{ token: THEME_OPTIONS }}>
          <App />
        </ConfigProvider>
      </SolanaProvider>
    </ReduxProvider>
  </React.StrictMode>,
);
