import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

export const NETWORK = WalletAdapterNetwork.Devnet;
export const PROGRAM_ID = "BFkXQPkpQc9xStauLQpHC9yty1EGihHArxMbPHGTkxq7";
export const RECEIVER_ADDRESS = "6FF3LNPX3n9rmsKFtboDibPj7jdzucgRwtcMz3WzAsRv";
export const TARGET_TOKEN_ADDRESS = [
  "2ZfNbqQpEaCoDs8EAsM8kzeWLGZedck3saWiTthvGCuE",
  "Du6ywmXmX9YJjVPSiU93qkZXjLbn1XgyxKStK1UCJwqe",
  "4F29dytP89AuunaU9Y2d85GFqoKszBX7Qj2DKDYve22L",
  "C9nojqu3tDrS5JTNYWgHEZwicrA7jNr5fg81WWUMNfvW",
  "EEbQxqu7tvoQmB7rK4vJXkQA7ocxP66CiCfik3VakFFy",
  "6cSq3WznNEMeD1kR7ngyuretEfN1f8jCTB8StnH6nnQz",
  "6TS2xMEX4rgJHCoQ9hstGzAf6KENBg1UAjjQKm4DK5g3",
  "AUrqpD2AYCahPb9TXrkzmi8njMN8vFcCqo8vv1R4e7Kf",
  "4aZwuoT97NcBFdDpePDxjBqhoSbukY82kG7NqMEkCydp",
  "EdaTCQpLxcYRrKnbkbPw2cs1Vgz3nPQ7H2Q4vG5zoLJq",
  "9XPq4BFXoajcHtkdyHgC8dwZaq2bgjYWKW6GDHJ6pdEB",
  "4dkTcJVRXZMcX4qSXeHvaxWTsszrGzR78emj4sQmFv6d",
  "5AC4gPBmTzxJsLBLrhnMneKVxZLorxjMRodMLy6rNymX",
  "GCjLhLHBAP6GJKGChEpYYBMK7Sf7UBMANcbagTiTb8Ji",
  "DYoEz1Sqqt1gCB5pZRy23apXjUQjiyMxMrKW8YkGw33W",
  "FaWhNh5fMWcFrLNU94FmyWsHympPDFzHDFJiUPuX23GW",
  "4j3EBPXgZricSSbavgefW851wrUZLMWzSSizhBn4HxRS",

  "6C9xQp1gi9oG1BD3HLVdvQuGZn3K9S1P5uef53MbiDh7",

  "GZbAN9JcBkP61Yu6h7ytAavLS3CY53b1mvzcuMnykVp6",
  "C1ojJ1RT1Nw4rmPQS3pWT1CUhBmMziDuSmYh9kLt6Rmv",
  "Fgrj1NBXwtYLComsVGBudAdozjmCqHGqCv7jTfZDABgP",
  "D6fifWdn8TPig8cKJkCHsqUSHg31gUokLymqSnZWVdM5",
  "13SWTFfKbdTZqhTESKodainSuMyJkSgLd4Xizm8edJK2",
  "6YBAHdPbCVnPqyVtFfysWFHW1ZsDviMBCkW8RvqzKPkS",
  "G8QgihiGYNqeyCeNq22GBGmLdTtccdnSJeoFbyR8zbk6",
  "7q1g2UknrM8uy8MVVWViUXdxDeGQYvVjNxw4bBDAu8ae",
  "3NgUYEniN5z7GRYrgFPGukH73BSXKVBgJ6JwKSBd5kpk",
  "9587MMVPyyVpGJz19ee8vH6F3mnxeb9CGfPk5vTGoYDh",
  "ziULDfR3YH6m2zCtEfiLSCnSg1uKduXzAVu8cendbvQ",
  "3nm2wtDfoYnrEgvQahYY9HLB9K3VuS8x3GrP8XQeZyZP",
  "AUcMZWmQj8WKrzatErpre1nr9VEobXakGuQyQ4zQZueL",
  "GRFxa5BSGHYFeNHj5zpfKkme16Pj3ECi7d6oAH4pZWRQ",
  "78CyMLGJdG6pL8892GSJWay6nZTytwhJzipwqZh6FgVm",
  "5FzpraZycdur9witYGbjAQ5dJgupvCFy2mYi4f7BYjug",
  "6tNgXmZBApedBXhmjwVYUyDUg2XJFo1kad6nRmUWbQJV",
  "6s5zJ8yKdoFCG43a6kYeLFrvRNcHLBg47pPxkcfE61EX",
  "EhMS86fRLJtMAnuc3sBv8CFXkTe793Stz29CKVWkoSCDv",
  "G4nptY99Di4h9BkqXUpHJvVPZSfwaHCRtjLjbzwCJ3wS",
  "Hz6gGz7GQ5xf7KUDqoytZQYApSQUMfiVBgyUnsetixvz",
  "8CTmzWmVnnA7NFWrFJVvAdSCNJroSDkAR8dUa1hcqdFW",
  "FFa1Pc8Sckuk6uT5J3hcvotZhcp4X7hjpgsNu16pkQVP",
  "BfMbiULqT5zUfMLZnzNqFm4vCFLbi1ZmnB74Gyy6HvH6",
  "7bZGkmq2ow3sryXDZzjWGwz284pC1WrMHkTKq3uJ8vd9",
  "FQW3atsaVyHx335ezjiszTpqTNCUXu6gc63tiwbs95hA",
  "EDz4d1igSvSDaLAQAQrEfQTpVkvG5fNXoRToR6GqZdPw",
  "449R1JGHUw27FpaUeUCqXdNDJSR5gobASefe5QBnPQa6",
  "51f8qufXJ4hNw6skr9FgcKdxLpE7FakWFLvtnRTq1oo",
  "FgYNfWPQiVzGT9BwL5eAStSxSGZ7G7joYw4EoFm2LU1g",
  "3fz3kfwHdMn3Qey1VV7pk7kjEU63RUCsCiE6E8QSNiaa",
  "AcH6xdtmdwUoaXbtgevBhV1nPjR3tCEKbc1xGdykvZr8",
  "CGAuZmGACEV2nR3SEQxWEfhLfZjqMXEb2p92s7GyABYd",
  "E4HkU8uzAvrZMNY3TrQY7vxEvYiEr9L6BoLVnUWMK1xA",
  "8SBw33CsEKFwJVw569by3EzxwGuaPfUXMN1Dc9RK4qT1",
  "3pxAYYn4DFS5EsfqExJm3a2FhatJ7qBW4zuQ2H4emHfX",
  "EUi521LQN9ABeJKHTzcGdavaut4oWPP82ANivLKL4DXq",
  "83cZJsT9nDUvsf3V5435YDH4uBffUprFxozKwqq9fKv5",
  "Am9VcDkmr6uHAw48ubpt8AceXiWivwRjnKYwCddvWRpz",
  "2T9C7vBLEGXqyFuBYFXBfVQXTP4CDWeViyW8rnCTvP7B",
  "JCGUeHcpsjNVPi1Xi1AD4kTMt37X4MKMg3LheetFrNaB",
  "77Pm9xyUyhUM9xcZ68zV7pUw5B4dzKPYX9Dqchgf2Wj3",
  "E4xSisEAfobmtmgd7EL4g19GkQbkBpNFTFAkkHtbvYk",
  "5nJuA5Y89y6XTUfWJ18Tbuz8gXtmTcC7QJaNGoUuXHp",
  "3oWVFgJp9A4k1X9KQS72ndcBdEgxJi7g3eza7VxSNFJg",
  "CSNZUvnktJ5yso5Z8FuGT6p1VxEkLTBGvVWRrwtWcQuD",
  "4sDHnQeHLArZXfBFpXCXZL7PNtmtm4yMKZueU5SyMpkQ",
  "HiwYwsfoEJcy9LoZMhTyF22yDNi3y2KauafypaxCJgrW",
  "CiMkqXnWXxNsA1Eq9mtshySUYd9Ac335Ckkay8FJvpLD",
  "9h5RCUHa1TSrTTBMMeqDbUTpmUgxSsLvr8RE86m9fqYT",
  "2SF9MNnyW3jBw3hEi4svGrfjcWqsyQDtMTBoZ7qE7yH8",
  "FGyenwggnYKTYg8jYTmFVDVebDL4BYeazqvS2jZpjLbo",
  "rjnNMmNJoMSry9i4dgn4jTmYfaqGdowKyUdbqQDWByA",
  "GxU1unLJyP7Qbz7hNuZ1yKa6TsMhnaLe5LHADNKahQ5h",
  "3FPK2612zxPPihFVn6aY4g1xftF3nKq1vR1gDojktvDZ",
  "Hir7rmv5VNA74HyVxWhXQcY3gZvm7D9FeSvhYUGjF2Qn",
  "3fDWBxFeWLDDqJMikhj7twtajXSstoDdXLuCwYtgpm2X",
  "27To1vYW5ohHiqc5GFRxYeg3ppzRYhnroenzNCpXq8Bg",
  "4yGdA66orbiKiBoUS5DF6RUcgseaQDQ9c4HsQus5YL2q",
  "2qwy3Wf6ZMZHvUm57xhySfWnjr2n9T14c8b5LSuFbMds",
  "4CyK7bw5KrtknfjCtnjuHeoj8kPnjTKEWogygiHeq7Fv",
  "4Sv7r4FvX5Z3Di9fCBo7i7FbqDD86h63UHyam2kQvjFj",
  "AdJS8SGzzcrtwyoSWKrHELxgaXebSawPNcH72oSaSKfV",
  "FS9ytRmCeFkP1BS4M5rRFsbrWLyoqki11QgNwpKjJDnF",
  "2yJ6HFqyUE7Q48wyAP5Uy8fZzsWwmQpnUR3GjcZ8bgvK",
  "6jC6ZDNvNFqN8EXuzoD1e3UFUDiUdemYE1YNg54YezWE",
  "AsuqN7MLiYS4u6cxStnjhc2N5SL1ZAUpiw6m61VxfbTU",
  "55xCgQVajZ1MKMk6G5EEJYpJY85HJj9zKMCxACJHqSzL",
  "8vAx8dEzw7qBToZQqgv9pc8sLMrh45Gyk7o2jW2BnvSf",
  "GEaJtNPjc3BJJuXTSx4DFuFZqTqL9XT3Z3RzsuNJXvG4",
  "L2FJ5YBXW8v62nF2MoXPbZFVAwYunNpGfU4KJ9N8xvu",
  "HffVNmE59dfj9wQrXi5EUpTuWu5pPHTdLo2s6FdPjyW7",
  "5dMWVG1Lq9yzx1EqzUszjE5AYmQ9qkTrSBQLgmJieZK5",
];
