import Button from "../../Button/Button";
import { useFormContext } from "react-hook-form";

const ButtonsBlock = ({ solBalance }) => {
  const {
    formState: { isSubmitting, isValid },
  } = useFormContext();

  const isDisabled = !isValid || solBalance < 1;

  return (
    <div className="flex flex-col items-center">
      <div>
        <Button
          htmlType="submit"
          loading={isSubmitting}
          disabled={isDisabled}
          className="buttonBase text-5xl disabled:text-3xl disabled:pb-12 border-none font-migration pointer-events-auto text-black shadow-none rounded-lg disabled:cursor-not-allowed"
        >
          DRAW
        </Button>
      </div>
     
    </div>
  );
};

export default ButtonsBlock;

