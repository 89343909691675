import React from "react";

const Home = () => {
  return (
    <>
      <main className="relative lg:mt-24 w-full flex-1 mx-auto overflow-x-hidden max-w-[1400px] flex justify-center items-center  md:h-screen pb-6">
        <div className="flex flex-col justify-between items-center gap-8 pt-4 pb-12 px-10 h-screen">
          <div className="max-w-[290px] flex flex-col items-center gap-5">
            <img alt="y00ts logo" width="400" src="/logo-y00tlabs.png" />
            <span className="flex flex-col gap-1 mt-2">
              <p className="font-landing text-md text-center -mt-10">a joint venture by</p>
              <br />
              <p className="font-landing text-md text-center -mt-8">y00ts and polygon</p>
            </span>
            <p className="text-justify text-md leading-4 tracking-tight">
              Established to foster diversity, inclusivity and innovation. Committed to serve as a
              bridge between brands, chains and communities. Created to ensure long-term
              sustainability across Web3, and beyond. Check out our{" "}
              <a href="./solutions" className="underline">
                solutions
              </a>{" "}
              and latest{" "}
              <a href="./draw" className="underline">
                initiatives
              </a>{" "}
            </p>
          </div>
          <img width="840" src="/y00ts-group.png" alt="home pagina" className="mb-0" />
        </div>
      </main>
    </>
  );
};

export default Home;
