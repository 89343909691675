import React from 'react';
import styled from 'styled-components';

const Squares = () => {
    const Square1 = styled.div`
        background: rgb(60, 104, 255);
        height: 18px;
        width: 18px;
    `;
    const Square2 = styled.div`
        background: rgb(255, 202, 224);
        height: 18px;
        width: 18px;
    `;
    const Square3 = styled.div`
        background: rgb(255, 212, 56);
        height: 18px;
        width: 18px;
    `;
    const Square4 = styled.div`
        background: rgb(255, 139, 74);
        height: 18px;
        width: 18px;
    `;
    const Square5 = styled.div`
        background: rgb(255, 53, 53);
        height: 18px;
        width: 18px;
    `;
    const Square6 = styled.div`
        background: rgb(39, 153, 107);
        height: 18px;
        width: 18px;
    `;

    return (
        <div className="flex items-center justify-center">
           <Square1 className="border border-black mx-[0.5px]" />
           <Square2 className="border border-black mx-[0.5px]" />
           <Square3 className="border border-black mx-[0.5px]" />
           <Square4 className="border border-black mx-[0.5px]" />
           <Square5 className="border border-black mx-[0.5px]" />
           <Square6 className="border border-black mx-[0.5px]" />
        </div>
    );
};

export default Squares;
